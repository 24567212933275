.typography--variant-h1 {
  font-size: 4rem;
  font-weight: 500;
  font-family: 'Podkova', -apple-system, BlinkMacSystemFont, sans-serif;
}

.typography--variant-h2 {
  font-size: 3.75rem;
  font-weight: 500;
  font-family: 'Podkova', -apple-system, BlinkMacSystemFont, sans-serif;
}

.typography--variant-h3 {
  font-size: 3rem;
  font-weight: 500;
  font-family: 'Podkova', -apple-system, BlinkMacSystemFont, sans-serif;
}

.typography--variant-h4 {
  font-size: 2.125rem;
  font-weight: 500;
  font-family: 'PT Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.typography--variant-subheading1 {
  font-size: 1.75rem;
  font-weight: 500;
  font-family: 'Podkova', -apple-system, BlinkMacSystemFont, sans-serif;
}

.typography--variant-subheading2 {
  font-size: 1.5rem;
  font-weight: 300;
  font-family: 'PT Mono', -apple-system, BlinkMacSystemFont, sans-serif;
  line-height: 2.5rem;
}

.typography--variant-body1 {
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 1rem;
  font-family: 'PT Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.typography--variant-body2 {
  font-size: 0.9rem;
  line-height: 1.75rem;
  margin-top: 0.8rem;
  font-family: 'PT Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.typography--variant-bodyHighlighted {
  background-color: rgba(255, 242, 172, 0.75);
  text-decoration: underline;
  display: inline;
  font-family: 'PT Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  padding-top: 10px;
}

.typography--color-primary {
  color: #212121;
}

.typography--color-secondary {
  color: #626262;
}
.typography--color-tertiary {
  color: #fff;
}
.typography--color-error {
  color: #eb5757;
}